import React from "react";
import './principalWindow.css';
import images from './images/images';

const PrincipalWindow = ({setIsPrincipal}) => {

    const goToMeet = () => {
        localStorage.setItem('currentView','true');
        setIsPrincipal(false);
    };

    const allUrls = {
        zoom: 'https://zoom.us/signin',
        google: 'https://meet.google.com/dtv-ruuz-tfz',
        microsoft: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=5e3ce6c0-2b1f-4285-8d4b-75ee78787346&redirect_uri=https%3A%2F%2Fteams.microsoft.com%2Fgo&state=eyJpZCI6IjU3OTU4NDQ3LWRkNTUtNDMxNi1hNzA1LTY2ZGM2ZGUwMjBjOCIsInRzIjoxNjM1ODgwNjk4LCJtZXRob2QiOiJyZWRpcmVjdEludGVyYWN0aW9uIn0%3D&nonce=45dd48a0-f401-47be-a232-a6e84e4672b7&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.3.4&client-request-id=130342c4-d7a4-4277-95e6-b24d3ddb541e&response_mode=fragment&sso_reload=true'
    };

    return(
        <div className={'container'}>
            <div className={'leftSide'}>
                <div className={'leftSideBody'}>
                    <h1 className={"titleRight"}>Denwa Meeting</h1>
                    <p className={'textIntro'}>{`Allows you to make a video conferences and collaboration`}</p>
                    <div onClick={goToMeet} className={'buttonContainer buttonMeeting'}>
                        <img src={images.denwaMeet} alt="Denwa Meeting" className={'buttonImage'} />
                        <p className={'title titleMeeting'}>Denwa Meeting</p>
                    </div>
                </div>
            </div>
            <div className={'rightSide'}>
                <div className={'buttonContainer'} >
                    <img src={images.zoom} alt="Zoom Video" className={'buttonImage'} />
                    <a href={allUrls['zoom']} target="_blank" rel="noopener noreferrer" className={'title'}>Zoom Video</a>
                </div>
                <div className={'buttonContainer'}>
                    <img src={images.meet} alt="Google Meet" className={'buttonImage'} />
                    <a href={allUrls['google']} target="_blank" rel="noopener noreferrer" className={'title'}>Google Meet</a>
                </div>
                <div className={'buttonContainer'}>
                    <img src={images.teams} alt="Microsoft Teams" className={'buttonImage'} />
                    <a href={allUrls['microsoft']} target="_blank" rel="noopener noreferrer" className={'title'}>Microsoft Teams</a>
                </div>
            </div>
        </div>
    )
};

export default PrincipalWindow;