export function getSignalingUrl(peerId, roomId)
{
    const port =
        process.env.NODE_ENV !== 'production' ?
            window.config.developmentPort
            :
            window.config.productionPort;
    const uri =
        process.env.NODE_ENV !== 'production' ?
            window.config.developmentUrl
            :
            window.location.hostname;

    return `wss://${uri}:${port}/?peerId=${peerId}&roomId=${roomId}`;
}
